<template>
  <div v-if="isLogged" class="csn-fn">
    <div v-if="showsPopup" class="csn-fn-popup">
      <p>
        <span>{{ t('account_balance') }}</span>
        <span>{{ balance }}</span>
      </p>
      <p>
        <span>{{ t('real_money') }}</span>
        <span>{{ realMoney }}</span>
      </p>
      <p>
        <span>{{ t('bonus_money') }}</span>
        <span>{{ bonusMoney }}</span>
      </p>
    </div>

    <ButtonLoader v-if="hasNoBalance" />
    <button
      @click="toggleCashButton"
      class="btn casino-btn casino-btn-default csn-fn-btn"
      :class="{
        'csn-fn-cash-btn-active': showsPopup && isCasinoPlatin,
        'csn-fn-cash-btn-active-casino-five': showsPopup && !isCasinoPlatin,
      }"
    >
      <CashIcon class="csn-fn-cash-icon" />
      <span class="csn-fn-cash">{{ balance }}</span>
      <DropdownArrow class="csn-fn-cash-btn-arrow" />
    </button>

    <Route
      :to="{ name: route.deposit }"
      class="btn casino-btn casino-btn-pink csn-fn-btn"
    >
      {{ t('deposit') }}
    </Route>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import {
  IS_MOBILE_SCREEN,
  Module,
  FOOTER_NAVBAR,
  RouteName,
  CASINO_INFO,
  IS_LOGGED,
  Digit,
  CASINO_PLATIN,
} from '@/constants'
import { dest, isNil } from '@/helpers'
import * as CasinoInfo from '@/models/getters/casinoInfo'

export default {
  name: FOOTER_NAVBAR,
  data: () => ({
    showsPopup: false,
  }),
  components: {
    Route: () => import('@/components/Route'),
    CashIcon: () => import('@/components/svg/Cash-Icon'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    DropdownArrow: () => import('@/components/svg/DropdownArrow'),
  },
  computed: {
    ...mapState(Module.CASINO_INFO, { CASINO_INFO }),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    ...mapGetters({ isLogged: dest([Module.USER, IS_LOGGED]) }),
    route: () => ({
      deposit: RouteName.DEPOSIT,
    }),
    balance() {
      return CasinoInfo.getAccountBalance(this.CASINO_INFO) || Digit.NOUGHT
    },
    realMoney() {
      return CasinoInfo.getAccountRealMoney(this.CASINO_INFO) || Digit.NOUGHT
    },
    bonusMoney() {
      return CasinoInfo.getAccountBonusMoney(this.CASINO_INFO) || Digit.NOUGHT
    },
    hasNoBalance() {
      return isNil(this.balance)
    },
    t() {
      return this.$createComponentTranslator(FOOTER_NAVBAR)
    },
    isCasinoPlatin: () => process.env.VUE_APP_THEME === CASINO_PLATIN,
  },
  watch: {
    $route() {
      this.showsPopup && (this.showsPopup = false)
    },
  },
  methods: {
    toggleCashButton() {
      this.showsPopup = !this.showsPopup
    },
  },
}
</script>
